import './Navbar.css'
import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from "react-router-dom"
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import Logo from '../../images/logo.png'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector } from "react-redux"

const useStyles = makeStyles({
    NavigateBtn: {
        background: 'none',
        border: 0,
        color: 'white !important',
        fontWeight: '400 !important',
        height: 70,
        padding: '0 30px !important',
        borderRadius: '0 !important',
    },
})

function Navbar() {

    const history = useHistory()
    const location = useLocation()

    // const [userData, setUserData] = useState()
    const [logoutModal, setLogoutModal] = useState(false)
    const dispatch = useDispatch()
    const { authData } = useSelector(state => state.authReducer)
    const sidebarOpen = useSelector(state => state.sidebarReducer.open)

    useEffect(() => {

        const title = (location.pathname.split('/')[1]).replace('-', ' ')
        document.title = title ? title : 'Home'

    }, [location])

    const handleLogout = (res) => {
        if (res) {
            dispatch({ type: 'LOGOUT' })
            history.push('./')
            // setUserData(null)
        }
        setLogoutModal(false)
    }

    const handleSidebar = () => {
        dispatch({ type: 'TOGGLE_SIDEBAR' })
    }

    const classes = useStyles()

    return (
        <>
            <div className="Navbar">
                <div className="max-container Navbar-wrap">
                    <Button className="Navbar-menu-btn" onClick={handleSidebar}>
                        {sidebarOpen ? <span className="material-icons-outlined ani-right">close</span> : <span className="material-icons-outlined ani-right">menu</span>}
                    </Button>
                    <Link to={{ pathname: '/' }}><img src={Logo} className="Navbar-logo" alt="navbar logo" /></Link>
                    <div className="Navbar-right-btns">
                        <Link to={{ pathname: '/' }}><Button className={classes.NavigateBtn}>Home</Button></Link>
                        {authData ?
                            <>
                                <Link to={{ pathname: '/backup/start' }}><Button className={classes.NavigateBtn}>Backup</Button></Link>
                                <Button style={{ marginLeft: "20px" }} variant="outlined">{authData.data.name}</Button>
                                <Button style={{ marginLeft: "20px" }} variant="contained" color="error" onClick={() => setLogoutModal(true)}>Logout</Button>
                            </>
                            : authData === null &&
                            <Link to={{ pathname: '/login' }} style={{ marginLeft: "20px" }}><Button variant="contained" color="secondary">Login</Button></Link>
                        }
                    </div>
                </div>
            </div >
            <Dialog
                open={logoutModal}
                keepMounted
                onClose={() => handleLogout(false)}
                aria-labelledby="alert-dialog-title">
                <DialogTitle>{"Are you sure you want to Logout?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleLogout(false)}>Cancel</Button>
                    <Button color="error" onClick={() => handleLogout(true)}>ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Navbar
