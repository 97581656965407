import { useEffect, useState } from 'react'
import {
    useLocation
} from "react-router-dom"
import * as api from '../../api/index'
import { BtnLoading } from '../Loading/Loading'
import { useDispatch } from "react-redux"
import { error } from '../../actions/backup'

export default function Reports() {

    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        const backupReport = async () => {
            try {
                const { status, data } = await api.backupReport()
                if (status === 200) {
                    if (data.res) {
                        setList(data.list)
                    } else if (!data.loggedIn) {
                        dispatch(error(data.message))
                    } else {
                        dispatch(error(data.err))
                    }
                } else {
                    console.log("Something went wrong")
                }
                setLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
        backupReport()
    }, [location, dispatch])

    return (
        <>
            <div className="backup-list">
                <h3 className="text-center my-4 ani-top">Reports</h3>
                <div className="list-container mb-3 px-1">
                    <div className="list-middel-container">
                        {!loading && (list.length === 0 ?
                            <h6 className="text-center mt-5 ani-top">
                                No Report Found
                            </h6>
                            : <>
                                <div className="list-items sticky ani-top">
                                    <div className="log-text-title">
                                        Status
                                    </div>
                                    <div className="log-data-time-title">
                                        Date & Time
                                    </div>
                                </div>
                                {list.map((item, key) => {
                                    return (
                                        <div className="list-items list-items-log ani-top" key={key}>
                                            <div className="log-text">
                                                {item.status}
                                            </div>
                                            <div className="log-data-time">
                                                {`${item.date} ${item.time}`}
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        )}
                    </div>
                </div>

                {loading && <BtnLoading />}

            </div>
        </>
    )
}