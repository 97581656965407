import React from "react";
import { Link, Switch, Route, useRouteMatch } from "react-router-dom";
import "./Backup.css";
import Button from "@mui/material/Button";
import Sidebar from "./Sidebar";
import Start from "./Start";
import Drive from "./Drive";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import Logs from "./Logs";
import Reports from "./Reports";
import E404 from "../Error/404";
import { useSelector } from "react-redux";
import { CustomSnackbar } from "../Snackbar/Index";

export default function Index() {
  const { path } = useRouteMatch();
  const { authData } = useSelector((state) => state.authReducer);

  return (
    <Switch>
      {authData ? (
        <>
          <div className="backup-container">
            <Sidebar />

            <div className="backup-content position-relative">
              <Route exact path={`${path}/start`} component={Start} />
              <Route exact path={`${path}/drive`} component={Drive} />
              <Route exact path={`${path}/list`} component={List} />
              <Route exact path={`${path}/add`} component={Add} />
              <Route exact path={`${path}/edit/:id`} component={Edit} />
              <Route exact path={`${path}/logs`} component={Logs} />
              <Route exact path={`${path}/reports`} component={Reports} />
            </div>
          </div>

          <CustomSnackbar />
        </>
      ) : (
        <Route
          exact
          path={[
            path,
            `${path}/start`,
            `${path}/drive`,
            `${path}/list`,
            `${path}/add`,
            `${path}/edit/:id`,
            `${path}/logs`,
            `${path}/reports`,
          ]}
        >
          {authData === null && (
            <div className="position-absolute d-flex justify-content-center align-items-center h-75 w-100">
              <Link to={{ pathname: "/login" }}>
                <Button variant="contained" size="large" className="m-3">
                  Login to view this page
                </Button>
              </Link>
            </div>
          )}
        </Route>
      )}
      <Route component={E404} />
    </Switch>
  );
}
