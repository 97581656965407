import './App.css'
import React, { useEffect } from 'react'
import {
	Switch,
	Route,
	useLocation
} from "react-router-dom"
import Home from './components/Home/Home'
import Login from './components/Auth/Login'
import Forgot from './components/Auth/Forgot'
import Navbar from './components/Navbar/Navbar'
import Sidebar from './components/Sidebar/Sidebar'
import Verify from './components/Auth/Verify'
import NewPassword from './components/Auth/NewPassword'
import Backup from './components/Backup/Index'
import CreateToken from './components/Backup/CreateToken'
import E404 from './components/Error/404'

import 'material-icons/iconfont/material-icons.css'

import * as api from './api/index'
import { useDispatch, useSelector } from "react-redux"

function App() {

	const location = useLocation()
	const dispatch = useDispatch()
	const { authData } = useSelector(state => state.authReducer)

	useEffect(() => {
		const idTokenValid = async () => {
			if (JSON.parse(localStorage.getItem('profile'))) {
				if (!authData) {
					try {
						const { status, data } = await api.verifyToken()
						if (status === 200) {
							if (data.loggedIn) {
								dispatch({ type: "SETUSER", data: localStorage.getItem('profile') })
							} else {
								dispatch({ type: "SETNULL" })
							}
						} else {
							console.log("Something went wrong")
						}
					} catch (err) {
						console.log(err)
					}
				}
			} else {
				dispatch({ type: "SETNULL" })
			}
		}

		idTokenValid()
	}, [location, authData, dispatch])

	return (
		<div className="App">
			<Navbar />
			<Sidebar />
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/login-verify"> <Verify type="Login" /> </Route>
				<Route exact path="/forgot" component={Forgot} />
				<Route exact path="/forgot-verify"> <Verify type="Forgot" /> </Route>
				<Route exact path="/forgot-new-password" component={NewPassword} />
				<Route exact path="/token" component={CreateToken} />
				<Route path="/backup" component={Backup} />
				<Route component={E404} />
			</Switch>
		</div>
	)
}

export default App
