import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Loading, { BtnLoading } from '../Loading/Loading'
import { useDispatch } from "react-redux"
import { success, error } from '../../actions/backup'
import * as api from '../../api/index'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

function Drive() {

    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)
    const [driveConnected, setdriveConnected] = useState()
    const [disconnectDriveModal, setDisconnectDriveModal] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {

        const isDriveConnected = async () => {
            try {
                const { status, data } = await api.isDriveConnected()
                if (status === 200) {
                    setdriveConnected(data.connected)
                } else {
                    console.log("Something went wrong")
                }
                setLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
        isDriveConnected()

    }, [])

    const connectDrive = async () => {
        setBtnLoading(true)
        try {
            const { status, data } = await api.connectDrive()
            if (status === 200) {
                if (data.connected) {
                    setdriveConnected(true)
                    dispatch(success(data.message))
                }
                else {
                    window.location.href = data.url
                }
            } else {
                console.log("Something went wrong")
            }
        } catch (err) {
            console.log(err)
        }
        setBtnLoading(false)
    }

    const disconnectDrive = () => {
        setDisconnectDriveModal(true)
    }

    const disconnectDriveModalClose = async (res) => {
        if (res) {
            setBtnLoading(true)
            try {
                const { status, data } = await api.disconnectDrive()
                if (status === 200) {
                    if (data.disConnected) {
                        setdriveConnected(false)
                        dispatch(success(data.message))
                    }
                    else {
                        dispatch(error(data.message))
                    }
                } else {
                    console.log("Something went wrong")
                }
            } catch (err) {
                console.log(err)
            }
            setBtnLoading(false)
        }
        setDisconnectDriveModal(false)
    }

    return (
        <div className="d-flex justify-content-center align-items-center flex-column mt-5">
            {driveConnected ? <>
                <h3 className="mb-4 text-success">Drive Connected</h3>
                <Button onClick={disconnectDrive} variant="contained" size="large">Disconnect Drive{btnLoading ? <BtnLoading color="#FFF" h="20" w="20" /> : ""}</Button></>
                : driveConnected === false && <>
                    <h3 className="mb-4 text-danger">Drive Disconnected</h3>
                    <Button onClick={connectDrive} variant="contained" size="large">Connect Drive{btnLoading ? <BtnLoading color="#FFF" h="20" w="20" /> : ""}</Button>
                </>}

            <Dialog
                open={disconnectDriveModal}
                keepMounted
                onClose={() => disconnectDriveModalClose(false)}
                aria-labelledby="alert-dialog-title">
                <DialogTitle>{"Are you sure you want disconnect drive?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => disconnectDriveModalClose(false)}>No</Button>
                    <Button color="error" onClick={() => disconnectDriveModalClose(true)}>Yes</Button>
                </DialogActions>
            </Dialog>

            {loading && <Loading position="absolute" />}
        </div>
    )
}

export default Drive