// import React from 'react'

export default function Validate(data) {

    return new Promise((resolve) => {

        let error = false
        let tmpError = false
        let mes
        let inputEle = document.querySelectorAll(`.${data.input}`)
        let errorEle = document.querySelectorAll(`.${data.error}`)
        let optional = []
        const email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        if (data.optional)
            optional = data.optional

        inputEle.forEach((ele, n) => {
            if (!ele.value && optional.includes(ele.dataset.c) === false) {
                tmpError = true
                mes = `Please enter ${ele.dataset.e}`
            }
            else if (ele.dataset.t === "text" && ele.value.length > 250) {
                tmpError = true
                mes = `You can't write more than 250 characters in ${ele.dataset.e}`
            }
            else if (ele.dataset.t === "email") {
                if (email_regex.test(ele.value) === false) {
                    tmpError = true
                    mes = `Invalid E-mail`
                }
            }
            else if (ele.dataset.t === "mobile" && ele.value.length !== 10) {
                tmpError = true
                mes = `Mobile number must contain 10 digits`
            }
            else if (ele.dataset.t === "password" && ele.value.length < 5) {
                tmpError = true
                mes = `Password must be at least 5 characters`
            }
            else if (ele.dataset.t === "radio") {
                const radios = document.getElementsByName(ele.name)
                let checked = false
                for (let i = 0; i < radios.length; i++) {
                    if (radios[i].checked) {
                        checked = true
                    }
                }
                if (!checked) {
                    tmpError = true
                    mes = `Please select ${ele.dataset.e}`
                }
            }

            if (tmpError) {
                ele.classList.add('wrong')
                errorEle[n].innerHTML = mes

                if (!error) {
                    error = true
                    ele.focus()
                }
            }
            else {
                ele.classList.remove('wrong')
                errorEle[n].innerHTML = ""
            }
            tmpError = false
        })
        if (!error) {
            resolve()
        }
    })
}

export function validateValue(e) {

    let charCode = (e.which) ? e.which : e.keyCode
    const valid = [8, 46, 37, 38, 39, 40]

    if (valid.includes(charCode)) {
        return true
    }

    const t = e.target.getAttribute("data-t")
    switch (t) {
        case "text": case "email": {
            if (e.target.value.length >= 250) {
                e.preventDefault()
            }
            break;
        }
        case "mobile": {
            if (e.target.value.length >= 10) {
                e.preventDefault()
            }
            else {
                isNumber(e)
            }
            break;
        }
        case "number": {
            if (e.target.getAttribute("data-l")) {
                if (e.target.value.length >= e.target.getAttribute("data-l")) {
                    e.preventDefault()
                }
            }
            isNumber(e)
            break;
        }
        default:
            break
    }

    function isNumber(evt) {
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            evt.preventDefault()
    }
}

/* function random(length, current) {
    current = current ? current : ''
    return length ? random(--length, "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".charAt(Math.floor(Math.random() * 60)) + current) : current
} */
