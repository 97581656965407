import React, { useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import * as api from '../../api/index'
import { useDispatch } from 'react-redux'
import { error } from '../../actions/backup'

function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function CreateToken() {

    const history = useHistory()
    const code = useQuery().get('code')
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        const setAccessToken = async () => {
            if (code) {
                try {
                    const { status, data } = await api.setAccessToken({ code })
                    if (status === 200) {
                        if (data.success) {
                            history.push("./backup/drive")
                        }
                        else {
                            dispatch(error(data.mes))
                        }
                    } else {
                        console.log("Something went wrong")
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            else {
                console.log("Code Not Get")
            }
        }
        setAccessToken()
    }, [location, code, history, dispatch])

    return (
        <></>
    )
}
