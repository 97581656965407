import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_API });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }
  return req;
});

// Authentication

export const login = (data) => API.post("/auth/login", data);
export const resendLoginOtp = (data) => API.post("/auth/resendLoginOtp", data);
export const verifyLoginOtp = (data) => API.post("/auth/verifyLoginOtp", data);
export const forgotPassword = (data) => API.post("/auth/forgotPassword", data);
export const resendForgotOtp = (data) =>
  API.post("/auth/resendForgotOtp", data);
export const verifyForgotOtp = (data) =>
  API.post("/auth/verifyForgotOtp", data);
export const setNewPassword = (data) => API.post("/auth/setNewPassword", data);
export const verifyToken = (data) => API.get("/auth/verifyToken", data);

// Backup

export const isDriveConnected = (data) =>
  API.get("backup/isDriveConnected", data);
export const connectDrive = (data) => API.post("backup/connectDrive", data);
export const disconnectDrive = (data) =>
  API.delete("backup/disconnectDrive", data);
export const setAccessToken = (data) => API.post("backup/setAccessToken", data);
export const startBackup = (data) => API.post("backup/startBackup", data);
export const backupList = (data) => API.get("backup/List", data);
export const backupLog = (data) => API.get("backup/log", data);
export const backupReport = (data) => API.get("backup/report", data);
export const changeStatus = (data) => API.put("backup/changeStatus", data);
export const deleteList = (data) => API.delete(`backup/deleteList/${data.id}`);
export const deleteMultiple = (data) => API.post("backup/deleteMultiple", data);
export const changeStatusMultiple = (data) =>
  API.put("backup/changeStatusMultiple", data);
export const addDomain = (data) => API.post("backup/addDomain", data);
export const editDomain = (data) => API.put("backup/editDomain", data);
export const fetchListData = (data) => API.post("backup/fetchListData", data);
