import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./LoginRegister.css";
import loginImg from "../../images/login.jpg";
import validate from "../Validate/Validate";
import Button from "@mui/material/Button";
import Loading from "../Loading/Loading";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../actions/auth";

function Forgot() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setEmailError("");

    validate({
      input: "forgot-field",
      error: "forgot-err",
    }).then(() => {
      setLoading(true);
      dispatch(forgotPassword({ email }, history, setEmailError, setLoading));
    });
  };

  return (
    <div className="forgot-pass">
      <div className="form-container">
        <div className="form-content">
          <div className="signin-image">
            <figure>
              <img src={loginImg} alt="login" />
            </figure>

            <Link to="./login" className="signup-image-link">
              I am already member
            </Link>
          </div>
          <div className="signin-form">
            <h2 className="form-title">Forgot Password</h2>
            <div className="w-100">
              <div className="l-form-group">
                <input
                  type="text"
                  name="your_email"
                  id="your_email"
                  className="text-field forgot-field"
                  data-t="text"
                  data-e="email"
                  placeholder="Your Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
                <div className="error forgot-err">{emailError}</div>
              </div>
              <div className="l-form-group form-button mt-3">
                <Button onClick={handleSubmit} variant="contained" size="large">
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading bgFilter="blur(5px)" />}
    </div>
  );
}

export default Forgot;
