import * as api from '../api/index'

export const login = (formData, history, setEmailError, setPassError, setLoading) => async (dispatch) => {
    try {
        const { status, data } = await api.login(formData)
        setLoading(false)
        if (status === 200) {
            if (data.sendOTP) {
                history.push("/login-verify")
            }
            else if (data.emailError) {
                setEmailError(data.message)
            }
            else if (data.passError) {
                setPassError(data.message)
            }
            else {
                console.log(data.message)
            }
        } else {
            console.log("Something went wrong")
        }
    } catch (err) {
        setLoading(false)
        console.log(err)
    }
}
export const resendLoginOtp = (formData, history, setLoading) => async (dispatch) => {
    try {
        const { status, data } = await api.resendLoginOtp(formData)
        setLoading(false)
        if (status === 200) {
            if (data.resendOTP) {
                console.log(data.message)
            }
            else {
                console.log(data.message)
            }
        } else {
            console.log("Something went wrong")
        }
    } catch (err) {
        setLoading(false)
        console.log(err)
    }
}
export const verifyLoginOtp = (formData, history, setOtpError, setLoading) => async (dispatch) => {
    try {
        const { status, data } = await api.verifyLoginOtp(formData)
        setLoading(false)
        if (status === 200) {
            if (data.loggedIn) {
                dispatch({ type: "AUTH", data: { data: data.profileObj, token: data.token } })
                history.push("/")
            }
            else if (data.otpError) {
                setOtpError(data.message)
            }
            else {
                console.log(data.message)
            }
        } else {
            console.log("Something went wrong")
        }
    } catch (err) {
        setLoading(false)
        console.log(err)
    }
}
export const forgotPassword = (formData, history, setEmailError, setLoading) => async (dispatch) => {
    try {
        const { status, data } = await api.forgotPassword(formData)
        setLoading(false)
        if (status === 200) {
            if (data.sendOTP) {
                history.push("/forgot-verify")
            }
            else if (data.emailError) {
                setEmailError(data.message)
            }
            else {
                console.log(data.message)
            }
        } else {
            console.log("Something went wrong")
        }
    } catch (err) {
        setLoading(false)
        console.log(err)
    }
}
export const resendForgotOtp = (formData, history, setLoading) => async (dispatch) => {
    try {
        const { status, data } = await api.resendForgotOtp(formData)
        setLoading(false)
        if (status === 200) {
            if (data.resendOTP) {
                console.log(data.message)
            }
            else {
                console.log(data.message)
            }
        } else {
            console.log("Something went wrong")
        }
    } catch (err) {
        setLoading(false)
        console.log(err)
    }
}
export const verifyForgotOtp = (formData, history, setOtpError, setLoading) => async (dispatch) => {
    try {
        const { status, data } = await api.verifyForgotOtp(formData)
        setLoading(false)
        if (status === 200) {
            if (data.verifyOTP) {
                history.push("/forgot-new-password")
            }
            else if (data.otpError) {
                setOtpError(data.message)
            }
            else {
                console.log(data.message)
            }
        } else {
            console.log("Something went wrong")
        }
    } catch (err) {
        setLoading(false)
        console.log(err)
    }
}

export const setNewPassword = (formData, history, setLoading) => async (dispatch) => {
    try {
        const { status, data } = await api.setNewPassword(formData)
        setLoading(false)
        if (status === 200) {
            if (data.setPassword) {
                history.push("./login")
            }
            else {
                console.log(data.message)
            }
        } else {
            console.log("Something went wrong")
        }
    } catch (err) {
        setLoading(false)
        console.log(err)
    }
}