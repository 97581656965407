import { createReducer } from "@reduxjs/toolkit"

export const authReducer = createReducer({ authData: undefined }, {
    AUTH: (state, action) => {
        state.authData = action.data
        localStorage.setItem('profile', JSON.stringify({ ...action.data }))
    },
    LOGOUT: (state) => {
        state.authData = null
        localStorage.removeItem('profile')
    },
    SETUSER: (state, action) => {
        state.authData = JSON.parse(action.data)
    },
    SETNULL: (state) => {
        state.authData = null
    }
})

export const sidebarReducer = createReducer({ open: false }, {
    OPEN_SIDEBAR: (state) => {
        state.open = true
    },
    CLOSE_SIDEBAR: (state) => {
        state.open = false
    },
    TOGGLE_SIDEBAR: (state) => {
        state.open = !state.open
    }
})

export const snackbarReducer = createReducer({ open: false, variant: "success", message: "", autoHideDuration: 0 }, {
    OPENSNACKBAR: (state, action) => {
        state.open = true
        state.variant = action.data.variant
        state.message = action.data.message
        state.autoHideDuration = action.data.autoHideDuration
    },
    CLOSESNACKBAR: (state) => {
        state.open = false
        state.variant = "success"
        state.message = ""
        state.autoHideDuration = 0
    }
})