import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();

  let url = location.pathname;
  url = url.split("/");
  const match =
    url[url.length - 1] === "" ? url[url.length - 2] : url[url.length - 1];

  return (
    <div className="backup-sidebar">
      <div className="b-sidebar-inner-wrap ani-right">
        <div className="b-sidebar-list-wrap">
          <div className="b-sidebar-list-container">
            <div className="b-sidebar-list-items">
              <Link
                to={{ pathname: "/backup/start" }}
                className={`b-sidebar-list-item${
                  match === "start" ? " active" : ""
                }`}
              >
                <div className="b-sidebar-list-item-icon">
                  <span className="material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div className="b-sidebar-list-item-text">
                  <span>Start Backup</span>
                </div>
              </Link>
              <Link
                to={{ pathname: "/backup/drive" }}
                className={`b-sidebar-list-item${
                  match === "drive" ? " active" : ""
                }`}
              >
                <div className="b-sidebar-list-item-icon">
                  <span className="material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div className="b-sidebar-list-item-text">
                  <span>Google Drive</span>
                </div>
              </Link>
              <Link
                to={{ pathname: "/backup/list" }}
                className={`b-sidebar-list-item${
                  match === "list" ? " active" : ""
                }`}
              >
                <div className="b-sidebar-list-item-icon">
                  <span className="material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div className="b-sidebar-list-item-text">
                  <span>Backup List</span>
                </div>
              </Link>
              <Link
                to={{ pathname: "/backup/add" }}
                className={`b-sidebar-list-item${
                  match === "add" ? " active" : ""
                }`}
              >
                <div className="b-sidebar-list-item-icon">
                  <span className="material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div className="b-sidebar-list-item-text">
                  <span>Add Backup</span>
                </div>
              </Link>
              <Link
                to={{ pathname: "/backup/logs" }}
                className={`b-sidebar-list-item${
                  match === "logs" ? " active" : ""
                }`}
              >
                <div className="b-sidebar-list-item-icon">
                  <span className="material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div className="b-sidebar-list-item-text">
                  <span>Backup Logs</span>
                </div>
              </Link>
              <Link
                to={{ pathname: "/backup/reports" }}
                className={`b-sidebar-list-item${
                  match === "reports" ? " active" : ""
                }`}
              >
                <div className="b-sidebar-list-item-icon">
                  <span className="material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div className="b-sidebar-list-item-text">
                  <span>Backup Reports</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
