import React from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Image from '../../images/404.svg';

export default function E404() {

    return (
        <div className="d-flex flex-column align-items-center gap-5 my-3">
            <h1>oh no! page not found</h1>
            <img style={{ width: '95%', maxWidth: '400px' }} src={Image} alt="" />
            <Link to={{ pathname: '/' }}><Button variant="contained" size="large">Back to home</Button></Link>
        </div>
    )
}

