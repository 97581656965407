import React from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { useSelector, useDispatch } from 'react-redux'

function CustomSnackbar() {

    const dispatch = useDispatch()

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })

    const { open, variant, message, autoHideDuration } = useSelector(state => state.snackbarReducer)

    const handleClose = (event, reason) => {
        if (reason !== 'clickaway') {
            dispatch({ "type": "CLOSESNACKBAR" })
        }
    }

    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={handleClose} className="custom-snakbar">
            <Alert onClose={handleClose} severity={variant}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export { CustomSnackbar }