import React, { useEffect } from 'react'
import {
    Link,
    useLocation
} from "react-router-dom"
import './Sidebar.css'
import { useDispatch, useSelector } from "react-redux"

export default function Sidebar() {

    const location = useLocation()
    const dispatch = useDispatch()
    const { authData } = useSelector(state => state.authReducer)
    const sidebarOpen = useSelector(state => state.sidebarReducer.open)
    const loginArray = ['login', 'login-verify', 'forgot', 'forgot-verify', 'forgot-new-password']

    let url = location.pathname
    url = url.split('/')
    const match = url[url.length - 1] === '' ? url[url.length - 2] : url[url.length - 1]

    const handleSidebarClick = () => {
        dispatch({ type: 'TOGGLE_SIDEBAR' })
    }

    useEffect(() => {

        let divToClick = document.getElementsByClassName('sidebar')[0]

        const handleSidebar = (e) => {
            if (e.target.className.split(' ')[0] === "sidebar") {
                dispatch({ type: 'TOGGLE_SIDEBAR' })
            }
        }

        divToClick.addEventListener('click', handleSidebar)
        return () => {
            divToClick.removeEventListener('click', handleSidebar)
        }
    }, [dispatch])
    return (
        <div className={`sidebar${sidebarOpen ? " open" : ""}`}>
            <div className="sidebar-inner-wrap ani-right">
                <div className="sidebar-list-wrap">
                    <div className="sidebar-list-container">
                        <div className="sidebar-list-items" onClick={handleSidebarClick}>
                            <Link to={{ pathname: '/' }} className={`sidebar-list-item${match === '' ? ' active' : ''}`}>
                                <div className="sidebar-list-item-icon">
                                    <span className="material-icons-outlined">
                                        radio_button_unchecked
                                    </span>
                                </div>
                                <div className="sidebar-list-item-text">
                                    <span>Home</span>
                                </div>
                            </Link>
                            {authData ? <>
                                <Link to={{ pathname: '/backup/start' }} className={`sidebar-list-item${match === 'start' ? ' active' : ''}`}>
                                    <div className="sidebar-list-item-icon">
                                        <span className="material-icons-outlined">
                                            radio_button_unchecked
                                        </span>
                                    </div>
                                    <div className="sidebar-list-item-text">
                                        <span>Backup</span>
                                    </div>
                                </Link>
                                <Link to={{ pathname: '/backup/drive' }} className={`sidebar-list-item${match === 'drive' ? ' active' : ''}`}>
                                    <div className="sidebar-list-item-icon">
                                        <span className="material-icons-outlined">
                                            radio_button_unchecked
                                        </span>
                                    </div>
                                    <div className="sidebar-list-item-text">
                                        <span>Google Drive</span>
                                    </div>
                                </Link>
                                <Link to={{ pathname: '/backup/list' }} className={`sidebar-list-item${match === 'list' ? ' active' : ''}`}>
                                    <div className="sidebar-list-item-icon">
                                        <span className="material-icons-outlined">
                                            radio_button_unchecked
                                        </span>
                                    </div>
                                    <div className="sidebar-list-item-text">
                                        <span>Backup List</span>
                                    </div>
                                </Link>
                                <Link to={{ pathname: '/backup/add' }} className={`sidebar-list-item${match === 'add' ? ' active' : ''}`}>
                                    <div className="sidebar-list-item-icon">
                                        <span className="material-icons-outlined">
                                            radio_button_unchecked
                                        </span>
                                    </div>
                                    <div className="sidebar-list-item-text">
                                        <span>Add Backup</span>
                                    </div>
                                </Link >
                                <Link to={{ pathname: '/backup/logs' }} className={`sidebar-list-item${match === 'logs' ? ' active' : ''}`}>
                                    <div className="sidebar-list-item-icon">
                                        <span className="material-icons-outlined">
                                            radio_button_unchecked
                                        </span>
                                    </div>
                                    <div className="sidebar-list-item-text">
                                        <span>Backup Logs</span>
                                    </div>
                                </Link>
                                <Link to={{ pathname: '/backup/reports' }} className={`sidebar-list-item${match === 'reports' ? ' active' : ''}`}>
                                    <div className="sidebar-list-item-icon">
                                        <span className="material-icons-outlined">
                                            radio_button_unchecked
                                        </span>
                                    </div>
                                    <div className="sidebar-list-item-text">
                                        <span>Backup Reports</span>
                                    </div>
                                </Link >
                            </> :
                                <Link to={{ pathname: '/login' }} className={`sidebar-list-item${loginArray.includes(match) ? ' active' : ''}`}>
                                    <div className="sidebar-list-item-icon">
                                        <span className="material-icons-outlined">
                                            radio_button_unchecked
                                        </span>
                                    </div>
                                    <div className="sidebar-list-item-text">
                                        <span>Login</span>
                                    </div>
                                </Link >
                            }
                        </div>
                    </div >
                </div >
            </div >
        </div >
    )
}
