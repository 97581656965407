import { useEffect, useState } from 'react'
import {
    useLocation
} from "react-router-dom"
import * as api from '../../api/index'
import { BtnLoading } from '../Loading/Loading'
import { useDispatch } from "react-redux"
import { error } from '../../actions/backup'

export default function Logs() {

    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [perList, setPerList] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        const backupLog = async () => {
            try {
                const { status, data } = await api.backupLog()
                if (status === 200) {
                    if (data.res) {
                        setList(data.list)
                        setPerList(data.list)
                    } else if (!data.loggedIn) {
                        dispatch(error(data.message))
                    } else {
                        dispatch(error(data.err))
                    }
                } else {
                    console.log("Something went wrong")
                }
                setLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
        backupLog()
    }, [location, dispatch])

    const handleDateFilter = (e) => {
        const date = e.target.value.split("-").reverse().join("-")
        date === '' ? setList(perList) : setList(perList.filter(item => item.date === date))
    }

    return (
        <>
            <div className="backup-list">
                <div className="backup-list-header ani-top">
                    <h3 className="my-4">Logs</h3>
                    <div className="date-f-wrap"><input type="date" className="log-date-filter" min="2021-01-01" max="2022-12-31" onChange={handleDateFilter} /></div>
                </div>
                <div className="list-container mb-3 px-1">
                    <div className="list-middel-container">
                        {!loading && (list.length === 0 ?
                            <h6 className="text-center mt-5 ani-top">
                                No Log Found
                            </h6>
                            : <>
                                <div className="list-items sticky ani-top">
                                    <div className="log-text-title">
                                        Text
                                    </div>
                                    <div className="log-data-time-title">
                                        Date & Time
                                    </div>
                                </div>
                                {list.map((item, key) => {
                                    return (
                                        <div className="list-items list-items-log ani-top" key={key}>
                                            <div className="log-text">
                                                <span className={item.status === 0 ? "text-danger" : ""}>{item.text}</span>
                                            </div>
                                            <div className="log-data-time">
                                                <span className={item.status === 0 ? "text-danger" : ""}>{`${item.date} ${item.time}`}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        )}
                    </div>
                </div>

                {loading && <BtnLoading />}

            </div>
        </>
    )
}