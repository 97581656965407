import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./LoginRegister.css";
import registerImg from "../../images/register.jpg";
import validate from "../Validate/Validate";
import Button from "@mui/material/Button";
import Loading from "../Loading/Loading";
import { useDispatch } from "react-redux";
import {
  resendLoginOtp,
  verifyLoginOtp,
  resendForgotOtp,
  verifyForgotOtp,
} from "../../actions/auth";

function Verify(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [otp, setOTP] = useState("");
  const [otpError, setOtpError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setOtpError("");

    validate({
      input: "verify-field",
      error: "verify-err",
    }).then(() => {
      setLoading(true);

      if (props.type === "Login")
        dispatch(verifyLoginOtp({ otp }, history, setOtpError, setLoading));
      else if (props.type === "Forgot")
        dispatch(verifyForgotOtp({ otp }, history, setOtpError, setLoading));
    });
  };

  const resendOTP = (e) => {
    setOtpError("");

    setLoading(true);

    if (props.type === "Login")
      dispatch(resendLoginOtp({}, history, setLoading));
    else if (props.type === "Forgot")
      dispatch(resendForgotOtp({}, history, setLoading));
  };

  return (
    <div className="verify">
      <div className="form-container">
        <div className="form-content">
          <div className="signup-form">
            <h2 className="form-title">Verify Account</h2>
            <div className="w-100">
              <div className="l-form-group">
                <input
                  type="text"
                  name="otp"
                  id="otp"
                  className="text-field verify-field"
                  data-t="number"
                  data-e="otp"
                  placeholder="Enter OTP"
                  autoComplete="off"
                  onChange={(e) => {
                    setOTP(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
                <div className="error verify-err">{otpError}</div>
              </div>
              <div className="l-form-group form-button">
                <Button onClick={handleSubmit} variant="contained" size="large">
                  Verify
                </Button>
                <Button
                  onClick={resendOTP}
                  variant="contained"
                  size="large"
                  style={{ marginLeft: "10px" }}
                >
                  Resend
                </Button>
              </div>
            </div>
          </div>
          <div className="signup-image">
            <figure>
              <img src={registerImg} alt="registe" />
            </figure>
            <Link to="../login" className="signup-image-link">
              I am already member
            </Link>
          </div>
        </div>
      </div>
      {loading && <Loading bgFilter="blur(5px)" />}
    </div>
  );
}

export default Verify;
