import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./LoginRegister.css";
import loginImg from "../../images/login.jpg";
import validate from "../Validate/Validate";
import Button from "@mui/material/Button";
import Loading from "../Loading/Loading";
import { useDispatch } from "react-redux";
import { setNewPassword } from "../../actions/auth";

function NewPassword() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [pass, setPassword] = useState("");
  const [passError, setPassError] = useState("");

  const [cpass, setCPassword] = useState("");
  const [cPassError, setCPassError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setPassError("");
    setCPassError("");

    validate({
      input: "newpass-field",
      error: "newpass-err",
    }).then(() => {
      if (pass !== cpass) {
        setCPassError("Password and confirm password not match");
      } else {
        setLoading(true);
        dispatch(setNewPassword({ pass }, history, setLoading));
      }
    });
  };

  return (
    <div className="forgot-pass">
      <div className="form-container">
        <div className="form-content">
          <div className="signin-image">
            <figure>
              <img src={loginImg} alt="login" />
            </figure>

            <Link to="./login" className="signup-image-link">
              I am already member
            </Link>
          </div>
          <div className="signin-form">
            <h2 className="form-title">Set New Password</h2>
            <div className="w-100">
              <div className="l-form-group">
                <input
                  type="text"
                  name="password"
                  id="password"
                  className="text-field newpass-field"
                  data-t="text"
                  data-e="password"
                  placeholder="Enter Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <div className="error newpass-err">{passError}</div>
              </div>
              <div className="l-form-group">
                <input
                  type="password"
                  name="cpassword"
                  id="cpassword"
                  className="text-field newpass-field"
                  data-t="text"
                  data-e="Confirm password"
                  placeholder="Enter Confirm Password"
                  onChange={(e) => {
                    setCPassword(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
                <div className="error newpass-err">{cPassError}</div>
              </div>
              <div className="l-form-group form-button mt-3">
                <Button onClick={handleSubmit} variant="contained" size="large">
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading bgFilter="blur(5px)" />}
    </div>
  );
}

export default NewPassword;
