
export const success = (mes, ahd = 5000) => async (dispatch) => {
    dispatch({ type: "OPENSNACKBAR", data: { variant: "success", message: mes, autoHideDuration: ahd } })
}

export const warning = (mes, ahd = 5000) => async (dispatch) => {
    dispatch({ type: "OPENSNACKBAR", data: { variant: "warning", message: mes, autoHideDuration: ahd } })
}

export const error = (mes, ahd = 5000) => async (dispatch) => {
    dispatch({ type: "OPENSNACKBAR", data: { variant: "error", message: mes, autoHideDuration: ahd } })
}