import { useState } from "react";
import * as api from "../../api/index";
import Loading from "../Loading/Loading";
import Button from "@mui/material/Button";
import Validate, { validateValue } from "../Validate/Validate";
import { useDispatch } from "react-redux";
import { error, success } from "../../actions/backup";

export default function Add() {
  const [loading, setLoading] = useState(false);
  const [databaseName, setDatabaseName] = useState("");
  const [folderName, setFolderName] = useState("");
  const [dStatus, setStatus] = useState(1);
  const dispatch = useDispatch();

  const handleAddDomain = async () => {
    Validate({
      input: "domain-add-field",
      error: "domain-add-err",
    }).then(async () => {
      setLoading(true);
      try {
        const { status, data } = await api.addDomain({
          databaseName,
          folderName,
          status: dStatus,
        });
        if (status === 200) {
          if (data.success) {
            setDatabaseName("");
            setFolderName("");
            dispatch(success(data.message));
          } else if (data.loggedIn === false) {
            dispatch(error(data.message));
          } else {
            dispatch(error(data.err));
          }
        } else {
          console.log("Something went wrong");
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    });
  };

  return (
    <div className="backup-list">
      <h2 className="text-center my-4 ani-top">Domain Add</h2>
      <div className="login ani-top">
        <div className="form-container domain-form">
          <div className="form-content pb-0">
            <div className="signin-form w-100">
              <div className="w-100">
                <div className="l-form-group">
                  <label htmlFor="db_name">Database Name</label>
                  <input
                    type="text"
                    name="db_name"
                    id="db_name"
                    className="text-field domain-add-field"
                    value={databaseName}
                    data-t="test"
                    data-e="database name"
                    placeholder="Enter database name"
                    onKeyDown={validateValue}
                    onChange={(e) => setDatabaseName(e.target.value)}
                  />
                  <div className="error domain-add-err"></div>
                </div>
                <div className="l-form-group">
                  <label htmlFor="f_name">Folder Name</label>
                  <input
                    type="text"
                    name="f_name"
                    id="f_name"
                    className="text-field domain-add-field"
                    value={folderName}
                    data-t="text"
                    data-e="folder name"
                    placeholder="Enter folder name"
                    onKeyDown={validateValue}
                    onChange={(e) => setFolderName(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleAddDomain();
                      }
                    }}
                  />
                  <div className="error domain-add-err"></div>
                </div>
                <div className="l-form-group">
                  <label htmlFor="da_a_status">Status :</label>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      type="radio"
                      name="da_status"
                      id="da_a_status"
                      className="domain-add-field"
                      checked={dStatus === 1 ? true : false}
                      data-t="radio"
                      data-e="status"
                      value="1"
                      onChange={(e) => setStatus(parseInt(e.target.value))}
                    />
                    <label htmlFor="da_a_status">Active</label>
                    <input
                      type="radio"
                      name="da_status"
                      id="da_d_status"
                      className=""
                      checked={dStatus === 0 ? true : false}
                      data-t="radio"
                      data-e="status"
                      value="0"
                      onChange={(e) => setStatus(parseInt(e.target.value))}
                    />
                    <label htmlFor="da_d_status">Deactive</label>
                  </div>
                  <div className="error domain-add-err"></div>
                </div>
                <div className="l-form-group form-button mt-3">
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleAddDomain}
                  >
                    Add Domain
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? <Loading /> : ""}
    </div>
  );
}
