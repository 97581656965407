import { React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as api from "../../api/index";
import { BtnLoading } from "../Loading/Loading";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import { success, error } from "../../actions/backup";

export default function List() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteMultipleModal, setDeleteMultipleModal] = useState(false);
    const [selectModal, setSelectModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    let [selectedList, setSelectedList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const backupList = async () => {
            try {
                const { status, data } = await api.backupList();
                if (status === 200) {
                    if (data.res) {
                        setList(
                            data.list.map((item) => {
                                return {
                                    ...item,
                                    selected: false,
                                    btns: false,
                                };
                            })
                        );
                    } else if (data.loggedIn === false) {
                        dispatch(error(data.message));
                    } else {
                        dispatch(error(data.err));
                    }
                } else {
                    console.log("Something went wrong");
                }
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        backupList();
    }, [location, dispatch]);

    const handleStatusChange = async (id, s) => {
        try {
            const { status, data } = await api.changeStatus({ id, status: s });
            if (status === 200) {
                if (data.success) {
                    setList(
                        list.map((item) => {
                            if (item.id === id) {
                                item.status = s;
                            }
                            return item;
                        })
                    );
                    dispatch(success(data.message));
                } else if (data.loggedIn === false) {
                    dispatch(error(data.message));
                } else {
                    dispatch(error(data.err));
                }
            } else {
                console.log("Something went wrong");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
    };

    const deleteModalClose = async (res) => {
        if (res) {
            try {
                const { status, data } = await api.deleteList({ id: deleteId });
                if (status === 200) {
                    if (data.success) {
                        setList(list.filter((item) => item.id !== deleteId));
                        setDeleteId(null);
                        dispatch(success(data.message));
                    } else if (data.loggedIn === false) {
                        dispatch(error(data.message));
                    } else {
                        dispatch(error(data.err));
                    }
                } else {
                    console.log("Something went wrong");
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            setDeleteId(null);
        }
        setDeleteModal(false);
    };

    const handleCheckAll = async () => {
        setSelectedList([]);
        if (!selectAll) {
            setList(
                list.map((item) => {
                    item.selected = true;
                    setSelectedList((selectedList) => [
                        ...selectedList,
                        item.id,
                    ]);
                    return item;
                })
            );
        } else {
            setList(
                list.map((item) => {
                    item.selected = false;
                    return item;
                })
            );
        }
    };

    const handleCheck = async (id) => {
        setList(
            list.map((item) => {
                if (item.id === id) {
                    item.selected = !item.selected;
                    if (item.selected) {
                        setSelectedList([...selectedList, item.id]);
                    } else {
                        setSelectedList(
                            selectedList.filter((item) => item !== id)
                        );
                    }
                }
                return item;
            })
        );
    };

    useEffect(() => {
        selectedList.length > 0 ? setSelectModal(true) : setSelectModal(false);
        if (list.length > 0)
            selectedList.length === list.length
                ? setSelectAll(true)
                : setSelectAll(false);
    }, [selectedList, list]);

    const deleteSelected = async () => {
        setDeleteMultipleModal(true);
    };

    const deleteMultipleModalClose = async (res) => {
        if (res) {
            if (selectedList.length > 0) {
                try {
                    const { status, data } = await api.deleteMultiple({
                        id: selectedList,
                    });
                    if (status === 200) {
                        if (data.success) {
                            setList(
                                list.filter(
                                    (item) => !selectedList.includes(item.id)
                                )
                            );
                            setSelectedList([]);
                            setSelectAll(false);
                            dispatch(success(data.message));
                        } else if (data.loggedIn === false) {
                            dispatch(error(data.message));
                        } else {
                            dispatch(error(data.err));
                        }
                    } else {
                        console.log("Something went wrong");
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                setSelectModal(false);
            }
        }
        setDeleteMultipleModal(false);
    };

    const changeStatusSelected = async (s) => {
        if (selectedList.length > 0) {
            try {
                const { status, data } = await api.changeStatusMultiple({
                    id: selectedList,
                    status: s,
                });
                if (status === 200) {
                    if (data.success) {
                        setList(
                            list.map((item) => {
                                if (selectedList.includes(item.id)) {
                                    item.status = s;
                                    item.selected = false;
                                }
                                return item;
                            })
                        );
                        setSelectedList([]);
                        setSelectAll(false);
                        dispatch(success(data.message));
                    } else if (data.loggedIn === false) {
                        dispatch(error(data.message));
                    } else {
                        dispatch(error(data.err));
                    }
                } else {
                    console.log("Something went wrong");
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            setSelectModal(false);
        }
    };

    const showActionBtn = (id) => {
        setList(
            list.map((item) => {
                item.id === id ? (item.btns = true) : (item.btns = false);
                return item;
            })
        );
    };

    const hideActionBtn = (id) => {
        setList(
            list.map((item) => {
                item.id === id ? (item.btns = false) : (item.btns = false);
                return item;
            })
        );
    };

    return (
        <>
            <div className="backup-list">
                <div className="backup-list-header ani-top">
                    <h3 className="my-4">Backup List</h3>
                    <div className="backup-list-header-btns">
                        {!selectModal && (
                            <Button
                                className="select-modal-open"
                                variant="contained"
                                onClick={() => setSelectModal(true)}
                            >
                                <span className="material-icons-outlined">
                                    more_horiz
                                </span>
                            </Button>
                        )}
                    </div>
                </div>
                <div className="list-container px-1">
                    <div className="list-middel-container">
                        <div className="list-items sticky ani-top">
                            <div className="field-checkbox">
                                <Checkbox
                                    aria-label="Checkbox"
                                    checked={selectAll}
                                    onClick={handleCheckAll}
                                />
                            </div>

                            <div className="field-name field-value-wrap">
                                <div className="f-v">Database Name</div>
                                <div className="f-v">Directory Name</div>
                            </div>
                            <div className="field-name action-title">
                                Actions
                            </div>
                        </div>
                        <div className="list-inner-container">
                            {!loading &&
                                (list.length === 0 ? (
                                    <h6 className="text-center mt-5 ani-top">
                                        No Backup List Found
                                    </h6>
                                ) : (
                                    <>
                                        {list.map((item, key) => {
                                            return (
                                                <div
                                                    className="list-items ani-top"
                                                    key={key}
                                                >
                                                    <div className="field-checkbox">
                                                        <Checkbox
                                                            aria-label="Checkbox"
                                                            checked={
                                                                item.selected
                                                            }
                                                            onClick={() =>
                                                                handleCheck(
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="field-value field-value-wrap">
                                                        <div className="f-v">
                                                            {item.db_name}
                                                        </div>
                                                        <div className="f-v">
                                                            {item.folder_name}
                                                        </div>
                                                    </div>
                                                    <div className="action-wrap action-wrap-m">
                                                        <div className="btn-group">
                                                            <Button
                                                                className="action-btn"
                                                                onClick={() =>
                                                                    showActionBtn(
                                                                        item.id
                                                                    )
                                                                }
                                                            >
                                                                <svg
                                                                    style={{
                                                                        width: "24px",
                                                                        height: "24px",
                                                                    }}
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
                                                                    />
                                                                </svg>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`action-wrap action-wrap-d ${
                                                            !item.btns &&
                                                            "btn-mobile-hide"
                                                        }`}
                                                    >
                                                        <Link
                                                            to={`./edit/${item.id}`}
                                                        >
                                                            <Button
                                                                className="action-btn"
                                                                title="Edit"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="19"
                                                                    height="19"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="blue"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                >
                                                                    <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                                                                    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                                                                </svg>
                                                            </Button>
                                                        </Link>
                                                        <Button
                                                            className="action-btn action-wrap-close"
                                                            variant="contained"
                                                            onClick={() =>
                                                                hideActionBtn(
                                                                    item.id
                                                                )
                                                            }
                                                        >
                                                            <svg
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                }}
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
                                                                />
                                                            </svg>
                                                        </Button>
                                                        <Button
                                                            className="action-btn"
                                                            title="Delete"
                                                            onClick={() =>
                                                                handleDelete(
                                                                    item.id
                                                                )
                                                            }
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="19"
                                                                height="19"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="red"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            >
                                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                                <line
                                                                    x1="10"
                                                                    y1="11"
                                                                    x2="10"
                                                                    y2="17"
                                                                ></line>
                                                                <line
                                                                    x1="14"
                                                                    y1="11"
                                                                    x2="14"
                                                                    y2="17"
                                                                ></line>
                                                            </svg>
                                                        </Button>
                                                        {item.status === 0 ? (
                                                            <Button
                                                                className="action-btn"
                                                                title="Active"
                                                                onClick={() =>
                                                                    handleStatusChange(
                                                                        item.id,
                                                                        1
                                                                    )
                                                                }
                                                            >
                                                                <svg
                                                                    style={{
                                                                        width: "21px",
                                                                        height: "21px",
                                                                    }}
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path
                                                                        fill="red"
                                                                        d="M17 6H7c-3.31 0-6 2.69-6 6s2.69 6 6 6h10c3.31 0 6-2.69 6-6s-2.69-6-6-6zm0 10H7c-2.21 0-4-1.79-4-4s1.79-4 4-4h10c2.21 0 4 1.79 4 4s-1.79 4-4 4zM7 9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                                                                    />
                                                                </svg>
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className="action-btn"
                                                                title="Deactive"
                                                                onClick={() =>
                                                                    handleStatusChange(
                                                                        item.id,
                                                                        0
                                                                    )
                                                                }
                                                            >
                                                                <svg
                                                                    style={{
                                                                        width: "21px",
                                                                        height: "21px",
                                                                    }}
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path
                                                                        fill="green"
                                                                        d="M17 6H7C3.69 6 1 8.69 1 12S3.69 18 7 18H17C20.31 18 23 15.31 23 12S20.31 6 17 6M17 16H7C4.79 16 3 14.21 3 12S4.79 8 7 8H17C19.21 8 21 9.79 21 12S19.21 16 17 16M17 9C15.34 9 14 10.34 14 12S15.34 15 17 15 20 13.66 20 12 18.66 9 17 9Z"
                                                                    />
                                                                </svg>
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                ))}
                        </div>
                    </div>

                    {loading && <BtnLoading />}
                </div>

                {selectModal && (
                    <div className="select-modal">
                        <Button
                            className="select-modal-close"
                            variant="contained"
                            onClick={() => setSelectModal(false)}
                        >
                            <svg
                                style={{ width: "24px", height: "24px" }}
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
                                />
                            </svg>
                        </Button>
                        <Button
                            className="select-btn"
                            variant="contained"
                            onClick={deleteSelected}
                        >
                            Delete Selected
                        </Button>
                        <div className="status-wrap mt-3">
                            <h6 className="my-3">Change Status</h6>
                            <Button
                                className="select-btn mx-2"
                                variant="contained"
                                onClick={() => changeStatusSelected(1)}
                            >
                                Active
                            </Button>
                            <Button
                                className="select-btn mx-2"
                                variant="contained"
                                onClick={() => changeStatusSelected(0)}
                            >
                                Deactive
                            </Button>
                        </div>
                    </div>
                )}

                <Dialog
                    open={deleteModal}
                    keepMounted
                    onClose={() => deleteModalClose(false)}
                    aria-labelledby="alert-dialog-title"
                >
                    <DialogTitle>
                        {"Are you sure you want to delete?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => deleteModalClose(false)}>
                            No
                        </Button>
                        <Button
                            color="error"
                            onClick={() => deleteModalClose(true)}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={deleteMultipleModal}
                    keepMounted
                    onClose={() => deleteMultipleModalClose(false)}
                    aria-labelledby="alert-dialog-title"
                >
                    <DialogTitle>
                        {"Are you sure you want to delete?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => deleteMultipleModalClose(false)}>
                            No
                        </Button>
                        <Button
                            color="error"
                            onClick={() => deleteMultipleModalClose(true)}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
