import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Loading from '../Loading/Loading'
import { useDispatch } from "react-redux"
import { success, error } from '../../actions/backup'
import * as api from '../../api/index'

function Start() {

    const [loading, setLoading] = useState(true)
    const [driveConnected, setdriveConnected] = useState()
    const dispatch = useDispatch()

    useEffect(() => {

        const isDriveConnected = async () => {
            try {
                const { status, data } = await api.isDriveConnected()
                if (status === 200) {
                    setdriveConnected(data.connected)
                } else {
                    console.log("Something went wrong")
                }
                setLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
        isDriveConnected()

    }, [])

    const startBackup = async () => {
        setLoading(true)
        try {
            const { status, data } = await api.startBackup({ key: process.env.REACT_APP_API_KEY })
            if (status === 200) {
                if (data.success) {
                    dispatch(success(data.message, null))
                }
                else {
                    dispatch(error(data.message, null))
                }
            } else {
                console.log("Something went wrong")
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    return (
        <>
            <div className="d-flex justify-content-center align-items-center mt-5">
                {driveConnected ? <Button onClick={startBackup} variant="contained" size="large" className="mx-2">Start Backup</Button>
                    : driveConnected === false && <h3 className="mb-4 text-danger">Drive Not Connected</h3>}
            </div>
            {loading && <Loading bgFilter="blur(5px)" position="absolute" />}
        </>
    )
}

export default Start