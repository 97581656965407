import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./LoginRegister.css";
import loginImg from "../../images/login.jpg";
import validate from "../Validate/Validate";
import Button from "@mui/material/Button";
import Loading from "../Loading/Loading";
import { useDispatch } from "react-redux";
import { login } from "../../actions/auth";

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [pass, setPass] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setEmailError("");
    setPassError("");

    validate({
      input: "login-field",
      error: "login-err",
    }).then(() => {
      setLoading(true);
      dispatch(
        login(
          { emailOrMobile, pass },
          history,
          setEmailError,
          setPassError,
          setLoading
        )
      );
    });
  };

  const handleShowPassword = () => {
    setVisibility(!visibility);
  };

  return (
    <div className="login">
      <div className="form-container">
        <div className="form-content">
          <div className="signin-image">
            <figure>
              <img src={loginImg} alt="login" />
            </figure>
            <Link to="./forgot" className="signup-image-link">
              Forgot password?
            </Link>
          </div>
          <form className="signin-form">
            <h2 className="form-title">Login</h2>
            <div className="w-100">
              <div className="l-form-group">
                <input
                  type="text"
                  name="your_email_mobile"
                  id="your_email_mobile"
                  className="text-field login-field"
                  data-t="text"
                  data-e="email or mobile"
                  placeholder="Your Email or Mobile"
                  onChange={(e) => {
                    setEmailOrMobile(e.target.value);
                  }}
                />
                <div className="error login-err">{emailError}</div>
              </div>
              <div className="l-form-group position-relative m-0">
                <input
                  type={visibility ? "text" : "password"}
                  name="your_pass"
                  id="your_pass"
                  className="text-field login-field"
                  data-t="text"
                  data-e="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  autoComplete="on"
                />
                {visibility ? (
                  <svg
                    className="pass-handle"
                    onClick={handleShowPassword}
                    style={{ width: "20px", height: "20px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="pass-handle"
                    onClick={handleShowPassword}
                    style={{ width: "20px", height: "20px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"
                    />
                  </svg>
                )}
                <div className="error login-err">{passError}</div>
              </div>
              <div className="l-form-group form-button mt-3">
                <Button onClick={handleSubmit} variant="contained" size="large">
                  Log in
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loading bgFilter="blur(5px)" />}
    </div>
  );
}

export default Login;
